<template>
  <div class="no-scrollbar text-shadow" style="white-space:nowrap;font-size:14px;margin:0;overflow:auto;opacity: 0.8;text-align: center;" :style="style">
    <ul class="list-unstyle">
      <template v-if="hasGroup()">
        <template v-for="(item,index) in groups">
          <template v-if="item&&!item.hide">
            <li v-if="getgroup(item).length>0" class="GroupItem" style="">
              <div style="position:relative;cursor:pointer;" @click="$emit('selectGroup',item)">
                <div class="text-truncate group" :class="{'active':groupGuid==item.guid}" style="text-align:center;font-size:10px;">
                  <a style="color:#fff;">
                    {{item.title||'未命名'}}
                  </a>
                </div>
              </div>
            </li>
          </template>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        //groups: []
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      style() {
        return this.config.style || {}
      },
      tour() {
        return this.publicData.tour || {}
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      groups() {
        var groups = this.tour.groups || []
        var g
        for (var i in this.tour.items) {
          var item = this.tour.items[i]
          //if (!item.groupName) {
          //    continue
          //}
          if (!(g = this.isInGroup(item.groupName, groups))) {
            g = this.newGroup(item.groupName)
            groups.push(g)
          }
          item.groupGUID = g.guid
        }
        return groups
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          changeGroupByGuid: this.changeGroupByGuid,
          changeGroup: this.changeGroup,
          getGroup: this.getGroup,
        },
        events: {
          selectGroup: {
            target: 'groups',
            name: 'changeGroup'
          }
        }
      })
      //this.getFunc('changePublicData')({ groupItems: this.getgroup(this.groupName) })
    },
    destroyed() {
    },
    methods: {
      getGroup(groupGuid) {
        console.log(groupGuid, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].guid == groupGuid) {
            return this.groups[i]
          }
        }
      },
      changeGroupByGuid(guid) {
        this.changeGroup(this.getGroup(guid))
      },
      changeGroup(item) {
        var groupItems = this.getFunc({ target: 'Data', name: 'getgroup' })(item.guid)
        console.log('changeGroup', item, groupItems)
        //this.getFunc('changePublicData')({ groupGuid: item.guid, groupItems, })
        if (item.type == 'Blog') {
          this.routertoGroup(item)
          this.getFunc('renewSence')('blog')
        } else if (item.type == 'Gallery') {
          this.routertoGroup(item)
          this.getFunc('renewSence')('gallery')
        } else {
          this.routerto(groupItems[0])
          this.getFunc('renewSence')('default')
        }
      },
      routerto(item, obj) {
        this.getFunc({ target: 'Data', name: 'toPano' })(item.guid,obj)
     //this.$router.push({ query: { senceId: item.guid, from: this.$route.query.from, ...obj } })
      },
      routertoGroup(group, obj) {
        this.getFunc({ target: 'Data', name: 'toGroup' })(group.guid, obj)
       //this.$router.push({ query: { groupId: group.guid, from: this.$route.query.from, ...obj } })
      },
      hasGroup() {
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      getgroup(item) {
        return this.getFunc({ target: 'Data', name: 'getgroup' })(item.guid) || []
      },
      newGroup(groupName) {
        return {
          title: groupName,
          description: '',
          audioAutoPlay: true,
          audioLoop: false,
          file: null,
          audio: null,
          type: 'Pano',
        }
      },
      isInGroup(groupName, groups) {
        for (var i in groups) {
          if (groups[i].title == groupName) {
            return groups[i]
          }
        }
        return false
      }
    },
  }
</script>
<style scoped>
  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }
</style>
